@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Poppins'
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Source Serif 4', 'Georgia', serif;
  font-weight: 200;
  font-size:20px;
  line-height:37px;

}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight:200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebarTitle {
  font-weight:200;
  text-decoration:none;
  color:#000;
}

.sidebarTitle h2 {
  font-family: 'Poppins', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight:200;
  letter-spacing: -.02rem;
}

.galleryContainer {
  margin-left: 220px;
  padding: 50px;
}

.galleryIntro {
  font-weight:400;
  color:rgba(0,0,0,0.6);
  display:block;
  max-width:800px;
  line-height:1.8em;
  padding-left:20px;
  font-size:18px;
}

.galleryTitle {
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  font-size:24px;
  font-weight:200;
  color:rgba(0,0,0,0.8);
  line-height:1.5em;
  padding-left:20px;
  
}

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: transparent;
  padding: 50px;
  position: fixed;
  left: 0;
  top: 0;
}

.sidebarTagline {
  text-decoration: none;
  color:#333;
  font-family:'Roboto';
  font-weight:400;
  font-size:12px;
  line-height:1.7em;
}

ul.sidebarList {
  padding:20px 0;
  margin:0;
  border-top:1px solid #ddd;
}

ul.sidebarList li a {
  text-decoration: none;
  color:#000;
  font-family:'Roboto';
  font-weight:300;
  font-size:20px;
  line-height:1.7em;
  list-style:none;
  list-style-image: none;
  list-style-image: none;
}

ul.second li a {
  font-size:16px;
}

ul.sidebarList li {
  padding:0;
  margin:0;
  list-style:none;
}

/* Image Grid */

.image-grid {
  column-count: 4;
  column-gap: 10px;
  padding: 20px;
}

.image-wrapper {
  margin-bottom: 10px;
  break-inside: avoid;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 2200px) {
  .image-grid {
    column-count: 3;
  }
}


@media (max-width: 1600px) {
  .image-grid {
    column-count: 2;
  }
}

@media (max-width: 1080px) {
  .image-grid {
    column-count: 1;
  }

  .sidebar {
    width: 100%;
    height: 100px;
    background-color: white;
    display:block;
    padding:0px 20px;
    box-shadow:0 0 10px rgba(0,0,0,0.2);
  }

  .sidebarTitle {
    font-size:14px;
    padding:0;
    margin:0;
  }

  ul.sidebarList {
    float:left;
    border:0;
    padding:0;
  }

  ul.sidebarList li {
    float:left;
  }

  ul.sidebarList li a {
    display:inline;
    font-size:12px;
    padding-right:15px;
  }

  .galleryContainer {
    margin-top:140px;
    margin-left: 0px;
    padding: 0px;
  }

  .sidebarTagline {
    display:none;
  }
}


/* Modal Styles */

/* The Modal (background) */
.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

/* Show class to trigger fade-in */
.modal.show {
  opacity: 1;
  pointer-events: auto;
}

/* Modal Content (image) */
.modal-content {
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  transform: scale(0.9);
  transition: transform 0.5s ease;
}

/* Zoom-in effect for the image */
.modal.show .modal-content {
  transform: scale(1);
}

/* Close Button */
.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
}